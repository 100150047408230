import React from "react"
import styled from "styled-components"
import { isMobile } from 'react-device-detect';

import Page from "./Page"
import SEO from "../seo"
import Loading from "../Loading"
import Layout2Columns from "./Layout2Columns";
import SideMenu from "./SideMenu"

import { colors } from "../../settings"

const PageGrid = styled.div`
    background-color: ${colors.lightGrey};
    padding: 15px !important;

    &.installation {
      h3 {
        font-size: 24px;
        border-bottom: 1px solid #999;
        margin: 15px 0px;
        padding: 10px 0px;
        font-weight: 500;

        small {
          color: #999;
        }
      }

      img {
        background-color: white;
        border-radius: 5px;

        &.full {
          width: 100%;
          max-width: auto;
          margin: 15px 0px;
        }
        &.half {
          display: block;
          width: 50%;
          margin: 15px auto;
        }
        &.center {
          display: block;
          margin: 15px auto;
        }
      }

      .help-block {
        color: #999;
        margin: 10px 25px;
      }

      .gridTextImage {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        > img {
          margin-left: 15px;
          max-width: 50% !important;
        }
      }
      .gridImageText {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        
        > img {
          margin-right: 15px;
          max-width: 50% !important;
        }
      }

      .warning.glasses {
        display: flex;
        align-items: center;

        img {
          width: 40px;
          padding: 5px;
          margin-right: 5px;
        }
      }
    }
`

const SupportPage = (props) => {
  return (
    <>
      <SEO page={props.page} title={props.title} />
      <Page padding={0}>
        {isMobile && 
            <SideMenu
                name="support"
                header="Support"
                active={props.page}
                items={[
                    {name: "support", title: "Support", url: "/support/"},
                    {name: "permits", title: "Permit Information", url: "/support/permits/"},
                    {name: "installation", title: "Installation Instructions", url: "/support/installation/"},
                    {name: "resources", title: "Resources", url: "/support/resources/"},
                    {name: "faq", title: "FAQ", url: "/support/frequently-asked-questions/"},
                    {name: "freeshipping", title: "Shipping Policy - FREE", url: "/support/shipping-policy/"},
                    {name: "contact", title: "Contact Us", url: "/contact-us/"},
                ]}
            />
        }
        <Layout2Columns align="top" padding="0" left={isMobile ? 100:30} right={70}>
            {!isMobile && 
              <div>
                  <SideMenu
                      name="support"
                      header="Support"
                      url="/support"
                      active={props.page}
                      items={[
                          {name: "permits", title: "Permit Information", url: "/support/permits"},
                          {name: "installation", title: "Installation Instructions", url: "/support/installation"},
                          {name: "resources", title: "Resources", url: "/support/resources"},
                          {name: "faq", title: "FAQ", url: "/support/frequently-asked-questions"},
                          {name: "freeshipping", title: "Shipping Policy - FREE", url: "/support/shipping-policy"},
                          {name: "contact", title: "Contact Us", url: "/contact-us"},
                      ]}
                  />
              </div>
            }
            <PageGrid className={props.page}>
                { props.loading ? 
                    <Loading />
                    :
                    props.children
                }
            </PageGrid>
        </Layout2Columns>
      </Page>
    </>
  )
}

export default SupportPage