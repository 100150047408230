import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import PageHeader from "../../components/layout/PageHeader"
import SupportPage from "../../components/layout/SupportPage"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faUser } from "@fortawesome/free-solid-svg-icons"

const BuyingGuidePage = () => {
	return (
		<SupportPage page="resources" title="Channel Letter Sign Buyers Guide">
			<PageHeader title="Channel Letters Buying Guide" />

			<p>
                Your business’s on premise signage is a vital link to customers, whether it is to physically mark your
                location to passersby, to promote impulse ‘stop-n-shop’, to create awareness and need for your
                products or services for future need, or to reinforce other forms of media advertising. Your sign may
                be the most cost-effective and efficient form of advertising and we’ve creative this guide to help you
                create a powerful advertising tool to attract more sales.
			</p>
			<p>
                If you wish to prepare before your initial consultation with us, you may go through the following steps
                and make notes.
			</p>

			<h2>Message</h2>

			<h3>Step 1:</h3>
			<p>
			Determine what your sign will say and keep in mind that ‘less is more’ when you are trying to deliver a
			quick message.
			</p>

			<h3>Step 2:</h3>
			<p>
			If you have a logo and want to use it, notice how the sign is to be built and how your logo will work in
			that environment. If you plan to use only letters for your sign, look at some different font styles and
			notice what appeals to you. You may also want to look at the Standard EZLit Type Styles they are
			available in five working days and at a lower cost. Many businesses use a font style as their identity/logo.
			</p>

			<h2>Budget</h2>
			<h3>Step 3:</h3>
			<p>
			When determining your budget, keep in mind that your sign is the best and most effective form of
			advertisement you will purchase and plan accordingly. We can help you determine budget for different
			types of signs depending on the size needed and complexity of your project. You should plan to budget
			$1000 - $4,500 for Face Lit Illuminated Channel Letters.
			</p>

			<h2>Colors</h2>
			<h3>Step 4:</h3>
			<p>
			Determine face colors you wish to have for the illuminated letters or logo. Standard colors are listed on
			back of this brochure. Blue and green letters should be outlined with white to enable them to show up
			well at night. This is done by applying translucent vinyl graphics to the face of the letter but leaving an
			outline of the white acrylic around the vinyl graphics.
			</p>
			<h3>Step 5:</h3>
			<p>
			Determine side colors you wish to have on the illuminated letters or logo.
			Please note that any color is possible but plan to budget more if you do not use standard colors.
			</p><p>

			</p><h2>Installation</h2>
			<h3>Step 6:</h3>
			<p>
			Determine the type of installation that you need for your sign. (Some strip malls require letters to be
			mounted to a raceway as this limits the number of holes in their structures.)
			</p>
			<ul>
				<li>
					<h4>Raceway Mounted:</h4>
					<p>
					Letter are mounted on raceway which simplifies installation. A raceway is a
					box that all the letters are connected too. With only one power connection through the wall.
					</p>
				</li>
				<li>
					<h4>Remote Power Mounted:</h4>
					<p>
					A transformer is mounted in the building and a wire is routed to each
					of the letters through the wall directly behind the letters. This option adds time to the
					installation and there must be access to the back of the wall where letters are to be mounted.
					</p>
					<p>
					Our Patented System is our EZLit® plug-n-Play. This system makes it possible for anyone to
					install remote channel letter safely and easily.
					</p>
				</li>
			</ul>

			<h3>Step 7:</h3>
			<p>
			Determine type of electrical service to the sign. 120 volt is standard but we can also accommodate 277
			volt. We will provide information on amperage requirements.
			</p>

			<h3>Step 8:</h3>
			<p>
			Is UL Listing required? Many municipalities require UL Listing for safety reasons. All SignMonkey.com
			electrical signs have the UL Label.
			</p>

			<h2>Deadlines</h2>

			<h3>Step 9:</h3>
			<p>
			Determine the due date for installation or shipping of sign. SignMonkey.com builds all signs using
			standard type styles in 5 days, custom typestyles take 5 days under normal circumstances.
			</p>

			<h2>Contact</h2>
			<p>
				Your new sign can be an economical way to display and reinforce your message. Unlike other media,
				your sign will be on the job 24/7/365 and it may give you the competitive edge to succeed. We hope to
				work with you on building a sign that fits your needs.
			</p>

			<br />

			<p>
                <Link to="/contact-us/">
                    <FontAwesomeSvgIcon icon={faUser} />{' '}
                    Contact a Salesperson
                </Link>
			</p>
		</SupportPage>
	)
}

export default BuyingGuidePage